/* SeatChangerReact 前端CSS样式表
   Author: pythonwby*/

@import 'lxgw-wenkai-lite-webfont/style.css';
body{
    font-family: "LXGW WenKai Lite", sans-serif;
}
.span-style {
    font-family: LXGW WenKai Lite;
    font-size: large;
    height: min-content;
}
.mobile-warning-span-style{
    font-family: LXGW WenKai Lite;
    font-size: medium;
    height: min-content;
}
.info-span-style{
    font-family: LXGW WenKai Lite;
    font-size: small;
    height: min-content;
}
.data-out-of-date-warning-style{
    font-family: LXGW WenKai Lite;
    font-size: xx-large;
    color: red;
    font-weight: bold;
    background-color: yellow;
}
.backgroundImage-style-light {
    background-image: url('./BackgroundImageLight.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
}
.backgroundImage-style-dark {
    background-image: url('./BackgroundImageDark.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
}
.no-backgroundImage {
    background-image: none;
    background-color: white;
}
.flipIcon-rotated {
    transform: rotate(-90deg);
}
.flipIcon-unRotated {
    transform: rotate(90deg);
}